<template>
  <div>
    <b-modal
      v-model="showLogModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      title="Logistic List"
    >
      <logistic-list v-if="showLogModal" :skuBase="selectSkuLog" />
    </b-modal>
    <div v-if="!order" class="d-flex justify-content-center">
      <b-spinner label="Loading..." />
    </div>
    <section
      v-else
      class="invoice-preview-wrapper"
    >
      <b-modal
        v-model="showGRCColisland"
        ok-only
        ok-title="Accept"
        centered
        size="xl"
        :hide-footer="true"
        title="Claim Colisland"
      >
        <a
          href="https://teams.microsoft.com/_#/xlsx/viewer/teamsSdk/https:~2F~2Fazuroutage06.sharepoint.com~2Fsites~2FSAV-KIPLI~2FDocuments%20partages~2FGeneral~2FKIPLI.xlsx?threadId=19:3b013aef6e6e449c8acc0ec06c1a4d32@thread.tacv2&subEntityId=%257B%2522viewParams%2522%253A%2522id%253D%25252Fsites%25252FSAV%25252DKIPLI%25252FDocuments%252520partages%25252FGeneral%2526viewid%253D0abb4f44%25252D73de%25252D40af%25252D94c0%25252Dacb5712d81d4%2522%257D&baseUrl=https:~2F~2Fazuroutage06.sharepoint.com~2Fsites~2FSAV-KIPLI&fileId=64a72f8b-cce1-4e58-b267-ec6e0931c554&ctx=openFilePreview&viewerAction=edit"
          target="_blank"
        >
          <b-button
            v-if="order.itemsLogistics.length && order.itemsLogistics.filter(t => t.logistic === 'CAR').length"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="mb-4"
          >
            <span class="align-middle">Tableau Colisland</span>
          </b-button>
        </a>
        <b-table-lite
          v-if="order.trackings && order.trackings.length"
          :items="order.trackings.filter(t => ['COL_6A', 'COL_CB', 'COL', 'B2CPP', 'VOID'].includes(t.carrier)).map(item => ({ 'dateDemande': $moment().format('DD/MM/YYYY'), 'dateExpedition': item.shippedAt ? $moment(item.shippedAt).format('DD/MM/YYYY') : '', 'orderNumber': order.number, 'number': item.trackingNumber, 'name': `${order.shipping.first_name} ${order.shipping.last_name}`, 'adress': order.shipping.address_1, 'postalcode': order.shipping.postcode, 'city': order.shipping.city, 'country': order.shipping.country }))"
          :fieds="[{key: 'dateDemande', name: 'Date de la demande'}, {key: 'dateExpedition', name: 'Date d\'expedition'}, {key: 'orderNumber', name: 'Numéro de commande'}, {key: 'number', name: 'Numéro colis'}, {key: 'name', name: 'Nom/Prénom'}, {key: 'adress', name: 'Adresse'}, {key: 'postalcode', name: 'CP'}, {key: 'city', name: 'Ville'}, {key: 'country', name: 'Pays'}]"
        />
        <!-- <iframe width="402" height="346" frameborder="0" scrolling="no" src="https://azuroutage06.sharepoint.com/sites/SAV-KIPLI/_layouts/15/Doc.aspx?sourcedoc={64a72f8b-cce1-4e58-b267-ec6e0931c554}&action=embedview&AllowTyping=True&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True&edesNext=true&resen=false&ed1JS=false"></iframe> -->
      </b-modal>
      <b-modal
        v-model="showLink"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Lien"
      >
        <p><strong>Lien du paiement à donner au client:</strong> <a :href="almaLink">{{ almaLink }}</a></p>
      </b-modal>
      <b-modal
        v-model="showChangeDate"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Date de livraison décalé"
      >
        <flat-pickr
          v-model="order.system.dateShipping"
          placeholder="Date"
          class="form-control mr-1"
          :config="{ disable:[{from:'2020-08-20',to: $moment().add(11, 'days').format('YYYY-MM-DD')}]}"
        />
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="updateOrder('date-delivery', order.system.dateShipping)"
          >
            <span class="align-middle">Enregistrer</span>
          </b-button>
        </div>
      </b-modal>
      <b-modal
        v-model="showComment"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Ajouter un commentaire"
      >
        <b-form-textarea
          id="event-description"
          v-model="comment"
          class="mb-1"
        />
        <div class="d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            @click="addComment"
          >
            <span class="align-middle">Enregistrer</span>
          </b-button>
        </div>
      </b-modal>
      <b-modal
        v-model="addProduct"
        size="lg"
      >
        <Products
          :country="order.system.shop"
          :reset="false"
          @add="changeProduct"
        />
      </b-modal>
      <b-modal
        v-model="openModalJustificatif"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Justificatif ( Chèque / Ticket )"
      >
        <b-row class="mb-2">
          <b-col
            cols="12"
          >
            <b-form-group
              label="Justificatif"
            >
              <b-col
                cols="6"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choisir un fichier ou glisser..."
                  drop-placeholder="Drop fichier ici..."
                  accept=".jpg, .png"
                  class="mt-2"
                />
                <b-button
                  v-if="file"
                  class="mt-1"
                  variant="primary"
                  :loading="loading"
                  @click="upload"
                >
                  Ajouter un justificatif
                </b-button>
              </b-col>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal
        v-model="openModalSplitPayment"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Paiement en plusieurs méthodes"
      >
        <b-row class="mb-2">
          <b-col
            v-for="(payment, keyP) of splitPayments"
            :key="keyP"
            cols="12"
          >
            <b-form-group
              label="Justificatif"
            >
              <b-row class="mb-2">
                <b-col
                  cols="6"
                >
                  <v-select
                    v-model="payment.type"
                    :options="['TPE', 'Chèque', 'Virement bancaire', 'Paypal', 'Stancer', 'ALMA', 'Espèce']"
                    placeholder="Type de paiement"
                    transition=""
                  />
                </b-col>
                <b-col
                  cols="4"
                >
                  <b-form-input
                    v-if="payment"
                    v-model="payment.value"
                    type="number"
                    placeholder="Montant (en €)"
                    class="mr-4"
                    style="width: 100%"
                  />
                </b-col>
                <b-col
                  cols="1"
                >
                <feather-icon
                    icon="TrashIcon"
                    class="ml-5 text-primary"
                    style="cursor: pointer;"
                    size="20"
                    @click="splitPayments.splice(keyP, 1);"
                  />
              </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-row class="mb-2 ml-1">
            <b-col
              cols="8"
            >
              <b-button
                class="mt-1"
                variant="secondary"
                @click="splitPayments.push({type: null, value: 0})"
              >
                Ajouter un moyen de paiement
              </b-button>
            </b-col>
            <b-col
              cols="4"
            >
              <b-button
                class="mt-1"
                variant="primary"
                :loading="loading"
                @click="updateOrder('payment-split')"
              >
                Enregistrer
              </b-button>
            </b-col>
          </b-row>
        </b-row>
      </b-modal>
      <b-modal
        v-model="openModalJustificatif"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Justificatif ( Chèque / Ticket )"
      >
        <b-row class="mb-2">
          <b-col
            cols="12"
          >
            <b-form-group
              label="Justificatif"
            >
              <b-col
                cols="6"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choisir un fichier ou glisser..."
                  drop-placeholder="Drop fichier ici..."
                  accept=".jpg, .png"
                  class="mt-2"
                />
                <b-button
                  v-if="file"
                  class="mt-1"
                  variant="primary"
                  :loading="loading"
                  @click="upload"
                >
                  Ajouter un justificatif
                </b-button>
              </b-col>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        v-model="openModalSinistre"
        ok-only
        ok-title="Accept"
        centered
        size="lg"
        :hide-footer="true"
        title="Sinistre"
      >
        <b-row class="mb-2">
          <b-col
            cols="12"
          >
            <b-form-group
              label="Sinistre"
            >
              <b-col
                cols="6"
              >
                <v-select v-model="sinistre" :options="['Problème de livraison', 'Défectueux']" />
                <b-button
                  class="mt-1"
                  variant="primary"
                  :loading="loading"
                  @click="updateOrder('sinistre', productSinistre)"
                >
                  Ajouter un sinistre
                </b-button>
              </b-col>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-row
        v-if="order"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="demo-inline-spacing mb-3">
                <b-button
                  variant="primary"
                  @click="openDuplicate"
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ isDuplicated ? 'Annuler' : 'Dupliquer' }}</span>
                </b-button>
                <generate-print :order="order" />
                <Defective v-if="false" :order="order" />
                <!-- <Claim v-if="true" :order="order" /> -->
                <Return :order="order" />
                <b-button
                  v-if="$route && ['completed', 'sended'].includes(order.status)"
                  :disabled="loading || $moment(order.date_created).isBefore(('03/15/2022')) || (['FR'].includes($route.params.id.split('-')[0]) && $moment(order.date_created).isBefore('04/01/2022')) && ['completed', 'sended'].includes(order.status)"
                  variant="primary"
                  @click="uploadFacture"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Facture</span>
                </b-button>
                <b-button
                  v-if="$route && ['processing', 'waiting-delivery'].includes(order.status)"
                  :disabled="loading || $moment(order.date_created).isBefore(('03/15/2022')) || (['FR'].includes($route.params.id.split('-')[0]) && $moment(order.date_created).isBefore('04/01/2022'))"
                  variant="primary"
                  @click="uploadBC"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Bon de commande</span>
                </b-button>
                <b-button
                  v-if="$route && !['processing', 'waiting-delivery', 'completed', 'sended'].includes(order.status)"
                  :disabled="loading || $moment(order.date_created).isBefore(('03/15/2022')) || (['FR'].includes($route.params.id.split('-')[0]) && $moment(order.date_created).isBefore('04/01/2022'))"
                  variant="primary"
                  @click="uploadDevis"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">PRO-FORMA</span>
                </b-button>
                <b-button
                  v-if="!isDuplicated"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="$router.push({ name: 'factureDetail', params: { id: order.number } })"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="mx-1 cursor-pointer"
                  />
                  <span class="align-middle">Facture SAV</span>
                </b-button>
                <b-button
                  v-if="!isDuplicated"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="warning"
                  class="btn-icon"
                >
                  <a class="text-white" :href="`https://kipli.com/fr/order/tracking?postalCode=${order.shipping.postcode}&orderNumber=${order.number}`" target="_blank">
                    <feather-icon
                      icon="TruckIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                    />
                    <span class="align-middle">Tracking</span>
                  </a>
                </b-button>
                <b-dropdown
                  id="dropdown-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  text="Autres"
                  variant="info"
                >
                  <b-dropdown-item v-if="!isDuplicated && ['pending', 'failed', 'cancelled'].includes(order.status)">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="secondary"
                      :disabled="order.sav && order.sav._finishedAt"
                      @click="sav('LOST')"
                    >
                      <feather-icon
                        icon="AlertOctagonIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Ignoré</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && ['pending', 'failed', 'cancelled'].includes(order.status)">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="success"
                      :disabled="order.sav && order.sav._finishedAt"
                      @click="sav('VALIDE')"
                    >
                      <feather-icon
                        icon="CheckCircleIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Valider</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && ['pending', 'failed', 'cancelled'].includes(order.status)">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="secondary"
                      :disabled="order.sav && order.sav._finishedAt"
                      @click="sav('NOT ANSWER')"
                    >
                      <feather-icon
                        icon="PhoneMissedIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Pas de réponse</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && ['pending', 'failed', 'cancelled'].includes(order.status)">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="info"
                      :disabled="order.sav && order.sav._finishedAt"
                      @click="sav('CALL')"
                    >
                      <feather-icon
                        icon="PhoneOutgoingIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">CALL</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated">
                    <b-button
                      variant="primary"
                      @click="showComment = true"
                    >
                      <feather-icon
                        icon="MessageCircleIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Commenter</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated">
                    <b-button
                      variant="primary"
                      @click="openModalJustificatif = true"
                    >
                      <feather-icon
                        icon="UploadIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Justificatif</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated">
                    <b-button
                      variant="primary"
                      @click="openModalSplitPayment = true"
                    >
                      <feather-icon
                        icon="UploadIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Plusieurs méthode de paiement</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <a href="https://kipli.gorgias.com/app/ticket/new" target="_blank">
                      <b-button
                        variant="primary"
                      >
                        <feather-icon
                          icon="MailIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Mail</span>
                      </b-button>
                    </a>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      @click="generatePayment(1)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                      />
                      <span class="align-middle">Créer lien de paiement</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && (order.status === 'failed' || order.status === 'pending')">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      @click="generatePayment(3)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                      />
                      <span class="align-middle">Créer paiement 3x</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && (order.status === 'failed' || order.status === 'pending')">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      @click="generatePayment(10)"
                    >
                      <feather-icon
                        icon="DollarIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                      />
                      <span class="align-middle">Créer paiement 10x</span>
                    </b-button>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!isDuplicated && (order.status === 'waiting-delivery' || order.status === 'processing' || order.status === 'on-hold' || order.status === 'pending')">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      @click="showChangeDate = true"
                    >
                      <feather-icon
                        icon="DollarIcon"
                        size="16"
                        class="mx-1 cursor-pointer"
                      />
                      <span class="align-middle">Livraison décalé</span>
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
                <b-badge
                  v-if="order.sav && order.sav.status"
                  pill
                  :variant="`${order.sav.status === 'VALIDE' ? 'success' : 'secondary'}`"
                  class="text-capitalize"
                >
                  {{ order.sav.status }}
                </b-badge>
                <a v-if="claims && claims.length" :href="`${$route.path}/claims`">
                  <b-badge
                    pill
                    variant="danger"
                    class="text-capitalize"
                  >
                    CLAIMS
                  </b-badge>
                </a>
              </div>

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <img
                      style="width: 40px;"
                      :src="require('@/assets/images/logo/kipli-logo.png')"
                    >

                    <h3 class="text-green invoice-logo">
                      Kipli
                    </h3>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-1">
                      Livraison:
                    </h6>
                    <feather-icon
                      v-b-toggle.order-sidebar-informations
                      size="12"
                      class="cursor-pointer"
                      icon="Edit2Icon"
                    />
                  </div>
                  <p class="card-text mb-25">
                    {{ order.shipping.first_name }} {{ order.shipping.last_name }}
                  </p>
                  <p class="card-text mb-25">
                    {{ order.shipping.address_1 }}
                  </p>
                  <p
                    v-if="order.shipping.address_2"
                    class="card-text mb-25"
                  >
                    {{ order.shipping.address_2 }}
                  </p>
                  <p
                    v-if="order.shipping.company"
                    class="card-text mb-25"
                  >
                    {{ order.shipping.company }}
                  </p>
                  <p class="card-text mb-25">
                    {{ order.shipping.postcode }} {{ order.shipping.city }}, {{ order.shipping.country }}
                  </p>
                  <p
                    v-if="order.meta_data.find(m => m.key === '_shipping_etage')"
                    class="card-text mb-25"
                  >
                    <strong><u>Etage: </u></strong>{{ order.meta_data.find(m => m.key === '_shipping_etage').value }}
                  </p>
                  <p
                    v-if="order.meta_data.find(m => m.key === '_shipping_ascenseur')"
                    class="card-text mb-25"
                  >
                    <strong><u>Ascenseur: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_ascenseur').value }}
                  </p>
                  <p
                    v-if="order.meta_data.find(m => m.key === '_shipping_refer_to')"
                    class="card-text mb-25"
                  >
                    <strong><u>Connu par: </u></strong> {{ order.meta_data.find(m => m.key === '_shipping_refer_to').value }}
                  </p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <div class="d-flex align-item-center">
                    <h4 class="invoice-title">
                      <span v-if="!isDuplicated"><strong><u>Passée le:</u></strong> {{ $moment(order.date_created).locale('fr').format('DD MMMM YYYY') }} à {{ $moment(order.date_created).locale('fr').format('HH:mm') }}</span>
                      <span v-else>Nouvelle commande</span>
                    </h4>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h6 class="mb-1">
                      Facturation:
                    </h6>
                    <feather-icon
                      v-b-toggle.order-sidebar-informations-billing
                      size="12"
                      class="cursor-pointer"
                      icon="Edit2Icon"
                    />
                  </div>
                  <h6 class="mb-25">
                    {{ order.billing.first_name }} {{ order.billing.last_name }}
                  </h6>
                  <p
                    v-if="order.billing.company"
                    class="card-text mb-25"
                  >
                    {{ order.billing.company }}
                  </p>
                  <p
                    v-if="order.billing.email"
                    class="card-text mb-25"
                  >
                    {{ order.billing.email }}
                  </p>
                  <p class="card-text mb-25">
                    {{ order.billing.address_1 }}
                  </p>
                  <p
                    v-if="order.billing.address_2"
                    class="card-text mb-25"
                  >
                    {{ order.billing.address_2 }}
                  </p>
                  <p class="card-text mb-25">
                    {{ order.billing.postcode }} {{ order.billing.city }}, {{ order.billing.country }}
                  </p>
                  <p
                    class="card-text mb-25"
                  >
                    {{ order.billing.phone !== '+' ? order.billing.phone : order.meta_data.find(m => m.key === '_shipping_phone') ? order.meta_data.find(m => m.key === '_shipping_phone').value : order.shipping.phone }}
                  </p>
                </div>
                <div class="mt-md-0 mt-2">
                  <div class="d-flex">
                    <h4 class="invoice-title mr-2">
                      Commande
                      <span
                        v-if="!isDuplicated"
                        class="invoice-number"
                      >#{{ order.number }}</span>
                    </h4>
                    <div>
                      <b-badge
                        v-if="!isDuplicated"
                        pill
                        :variant="`light-${resolveStatusVariant(order.status)}`"
                        class="text-capitalize"
                      >
                        {{ order.status }}
                      </b-badge>
                    </div>
                  </div>
                  <div v-if="order.creator">
                    <p><strong>Créé par</strong> <b-badge
                      v-if="!isDuplicated"
                      pill
                      variant="light-success"
                    >
                      {{ order.creator }}
                    </b-badge></p>

                  </div>
                  <div v-if="isDuplicated">
                    <p>Statut:</p>
                    <v-select
                      v-model="status"
                      :options="statusOptions"
                      :clearable="false"
                    />
                  </div>
                  <div v-if="isDuplicated" class="mt-2" >
                    <p>Back-office: <strong>{{ order.system.shop }} </strong></p>
                    <!-- <v-select
                      v-model="order.system.shop"
                      :options="countries"
                      :clearable="false"
                    /> -->
                  </div>
                  <div v-if="!isDuplicated">
                    <h6 class="mb-2">
                      Détail du paiement:
                    </h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            Total:
                          </td>
                          <td><span class="font-weight-bold">{{ order.total }}€</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Type:
                          </td>
                          <td>{{ order.payment_method }}</td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            Paiement:
                          </td>
                          <td>{{ order.payment_method_title }}</td>
                        </tr>
                        <tr v-if="order.date_paid">
                          <td class="pr-1">
                            Date de paiement:
                          </td>
                          <td>{{ $moment(order.date_paid).format('DD-MM-YYYY') }}</td>
                        </tr>
                        <tr v-if="order.system.refundedAt">
                          <td class="pr-1">
                            Date de remboursement:
                          </td>
                          <td>{{ $moment(new Date(order.system.refundedAt * 1000)).format('DD-MM-YYYY') }}</td>
                        </tr>
                        <tr v-if="order.transaction_id">
                          <td class="pr-1">
                            Identifiant:
                          </td>
                          <td v-if="order && ['thunes', 'paypal'].includes(order.payment_method)">
                            <a :href="`https://dashboard.limonetik.com/orders/${order.transaction_id}`" target="_blank" class="text-primary">
                              <td>{{ order.transaction_id }}</td>
                            </a>
                          </td>
                          <td v-else-if="order && order.payment_method.includes('alma')">
                            <a :href="`https://dashboard.getalma.eu/payments/${order.transaction_id}`" target="_blank" class="text-primary">
                              <td>{{ order.transaction_id }}</td>
                            </a>
                          </td>
                          <td v-else>{{ order.transaction_id }}</td>
                        </tr>
                        <tr v-if="order.meta_data.find(m => m.key === '_shipping_fiscal_code') && order.meta_data.find(m => m.key === '_shipping_fiscal_code').value !== ''">
                          <td class="pr-1">
                            Code Fiscal:
                          </td>
                          <td>{{ order.meta_data.find(m => m.key === '_shipping_fiscal_code').value }}</td>
                        </tr>
                        <tr v-if="order.status">
                          <td class="pr-1">
                            Statut:
                          </td>
                          <td><strong class="text-capitalize">{{ order.status }}</strong>
                            <feather-icon
                              size="12"
                              class="ml-1 cursor-pointer"
                              icon="Edit2Icon"
                              @click="changeStatus = !changeStatus"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div v-if="changeStatus" class="mt-1">
                      <b-col
                        md="6"
                        sm="12"
                        xs="12"
                      >
                        <p v-if="!isDuplicated && order.meta_data.find(m => m.key === '_date_for_shipping')" class="text-danger font-weight-bold">
                          ATTENTION ! LIVRAISON DECALEE !
                        </p>
                        <v-select
                          v-model="status"
                          :options="statusOptions"
                          :clearable="false"
                        />
                        <div class="d-flex align-items-center">
                          <b-button
                            variant="text"
                            @click="changeStatus = false"
                          >
                            <small>Annuler</small>
                          </b-button>
                          <b-button
                            variant="text"
                            class="text-warning"
                            @click="updateOrder('status', status)"
                          >
                            <small>Enregistrer</small>
                          </b-button>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>

            <b-row
              v-if="order && !isDuplicated && (order.status === 'failed' || order.status === 'cancelled')"
              class="invoice-preview mb-6"
            >

              <!-- Col: Left (Invoice Container) -->
              <b-col
                md="6"
                sm="12"
                xs="12"
              >
                <b-card no-body>
                  <b-card-header>
                    <div class="d-flex align-items-center">
                      <feather-icon
                        size="24"
                        class="mr-1"
                        icon="ListIcon"
                      />
                      <b-card-title class="ml-25">
                        Timeline
                      </b-card-title>
                    </div>
                  </b-card-header>

                  <!-- timeline -->
                  <b-card-body>
                    <app-timeline v-if="order.notes && order.notes.length">
                      <div
                        v-for="(h, key) of order.notes"
                        :key="key"
                      >
                        <app-timeline-item
                          class="mt-2"
                          :title="h.note"
                          :subtitle="`Fait par ${h.author}`"
                          :time="$moment(h.date_created).format('hh:mm / DD-MM-YYYY')"
                          variant="danger"
                        />
                      </div>
                    </app-timeline>
                    <h3 v-if="order.sav && order.sav.historic && order.sav.historic.length">
                      SAV Timeline
                    </h3>
                    <app-timeline v-if="order.sav && order.sav.historic && order.sav.historic.length">
                      <div
                        v-for="(h, key) of order.sav.historic"
                        :key="key"
                      >
                        <app-timeline-item
                          class="mt-2"
                          :title="h.text"
                          :subtitle="h.user"
                          :time="$moment(h.created_at).format('DD-MM-YYYY')"
                          variant="danger"
                        />
                      </div>
                    </app-timeline>
                    <!--/ timeline -->
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col
                md="6"
                sm="12"
                xs="12"
              >
                <b-card no-body>
                  <b-card-header>
                    <div class="d-flex align-items-center">
                      <feather-icon
                        size="24"
                        class="mr-1"
                        icon="ListIcon"
                      />
                      <b-card-title class="ml-25">
                        Commentaire
                      </b-card-title>
                    </div>
                  </b-card-header>

                  <!-- timeline -->
                  <b-card-body>
                    <app-timeline v-if="order.sav && order.sav.comments && order.sav.comments.length">
                      <div
                        v-for="(c, keyC) of order.sav.comments"
                        :key="keyC"
                      >
                        <app-timeline-item
                          class="mt-2"
                          :title="c.text"
                          :subtitle="c.user"
                          :time="$moment(c.created_at).format('DD-MM-YYYY')"
                          variant="success"
                        />
                        <b-button
                          v-if="c._user === me._id"
                          variant="text"
                          class="text-warning"
                          @click="deleteComment(keyC)"
                        >
                          <small>Supprimer</small>
                        </b-button>
                      </div>
                    </app-timeline>
                    <!--/ timeline -->
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <!-- Spacer -->
            <!-- <hr class="invoice-spacing"> -->

            <!-- Invoice Client & Payment Details -->

            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :items="isDuplicated ? items : order.line_items"
              :fields="['image', 'name', 'quantity', 'ht', 'tva', 'total']"
            >
              <template #cell(image)="data">
                <img
                  v-if="order.products && data.item && order.products.find(i => i._id === data.item.product_id)"
                  :src="order.products.find(i => i._id === data.item.product_id).src"
                  style="width: 90px;"
                >
                <img
                  v-else-if="data.item.image"
                  :src="data.item.image.src ? data.item.image.src : data.item.image"
                  style="width: 90px;"
                >
              </template>
              <template #cell(name)="data">
                <b-card-text class="font-weight-bold mb-25">
                  <p>
                    {{ data.item.name || data.item.title }}
                  </p>
                  <b-badge
                    v-if="isDuplicated && getStockStatus(data.item)"
                    pill
                    :variant="`${getStockStatus(data.item) === 'instock' ? 'success' : 'danger'}`"
                    class="text-uppercase mb-1"
                  >
                    {{ getStockStatus(data.item) === 'instock' ? 'IN STOCK' : 'ATTENTION ! OUT OF STOCK !' }}
                  </b-badge>
                  <b-badge
                    v-if="!isDuplicated"
                    pill
                    :variant="`${data.item.dispatch === 'Dispatché' ? 'success' : 'secondary'}`"
                    class="mb-1"
                  >
                    {{ data.item.dispatch ? data.item.dispatch : 'Non dispatché' }} {{ data.item.dateDispatch ? ` le ${data.item.dateDispatch}` : '' }} {{ data.item.logistic ? ` avec ${data.item.logistic}` : '' }} {{ data.item.shipper ? `/ ${data.item.shipper}` : '' }}
                  </b-badge>
                  <div v-if="!isDuplicated && order.system && order.system.items && order.system.items.length && order.system.items.find(i => i.id === data.item.id)">
                    <div v-if="data.item.dispatch === 'Dispatché' && order.system.items.find(i => i.id === data.item.id) && getTrackingLink(order.system.items.find(i => i.id === data.item.id)) && ['Agediss', 'CAR'].includes(order.system.items.find(i => i.id === data.item.id).logistic) && ['Agediss', 'B2C', 'Hermes', 'GLS', 'UPS'].includes(order.system.items.find(i => i.id === data.item.id).shipper)">
                      <div>
                        <a :href="getTrackingLink(order.system.items.find(i => i.id === data.item.id))" target="_blank">
                          <b-badge
                            pill
                            variant="primary"
                            class="mb-1"
                          >
                            <feather-icon
                              size="12"
                              class="cursor-pointer"
                              icon="TruckIcon"
                            />
                            Track Link
                          </b-badge>
                        </a>
                        <b-badge
                          pill
                          variant="primary"
                          class="mb-1 ml-1"
                        >
                          Last Status: {{ getLastEvent(order.system.items.find(i => i.id === data.item.id)) }}
                        </b-badge>
                      </div>
                      <b-badge
                        v-if="data.item.dispatch === 'Dispatché' && order.system.items.find(i => i.id === data.item.id) && ['Agediss'].includes(order.system.items.find(i => i.id === data.item.id).logistic) && order.agedissRDV"
                        pill
                        variant="secondary"
                        class="mb-1"
                      >
                        RDV Livraison: {{ order.agedissRDV }}
                      </b-badge>
                      <b-badge
                        v-if="data.item.dispatch === 'Dispatché' && order.system.items.find(i => i.id === data.item.id) && order.trackings && order.trackings.length && getDateReceived(order.system.items.find(i => i.id === data.item.id))"
                        pill
                        variant="success"
                        class="mb-1"
                      >
                        Livré le: {{ getDateReceived(order.system.items.find(i => i.id === data.item.id)) }}
                      </b-badge>
                    </div>
                    <div v-else-if="order.system.items.find(i => i.id === data.item.id).tracking">
                      <a :href="`${order.system.items.find(i => i.id === data.item.id).shipper === 'UPS' ? `https://wwwapps.ups.com/WebTracking/track?loc=en_IT&trackNums=${order.system.items.find(i => i.id === data.item.id).tracking}` : order.system.items.find(i => i.id === data.item.id).shipper === 'BRT' ? `https://vas.brt.it/vas/sped_det_show.hsm?referer=sped_numspe_par.htm&ChiSono=${order.system.items.find(i => i.id === data.item.id).tracking.slice(0, -2)}&ClienteMittente=&DataInizio=&DataFine=&RicercaChiSono=Ricerca` : order.shipping.country === 'FR' ? `https://gls-group.eu/FR/fr/suivi-colis.html?match=${order.system.items.find(i => i.id === data.item.id).tracking}` : `https://gls-group.eu/GROUP/en/parcel-tracking?match=${order.system.items.find(i => i.id === data.item.id).tracking}`}`" target="_blank">
                        <b-badge
                          pill
                          variant="primary"
                          class="mb-1"
                        >
                          <feather-icon
                            size="12"
                            class="cursor-pointer"
                            icon="TruckIcon"
                          />
                          Tracking Link
                        </b-badge>
                      </a>
                    </div>
                  </div>
                  <div v-if="!isDuplicated && ['GommaGomma', 'Flexilan', 'Radi'].includes(data.item.logistic)">
                    <b-badge
                      pill
                      variant="secondary"
                      class="mb-1"
                    >
                      Expédition prévue au plus tard le {{ getDays(data.item.logistic, data.item.dateDispatch, data.item.sku) }}
                    </b-badge>
                  </div>
                  <feather-icon
                    v-if="!isDuplicated"
                    size="12"
                    class="ml-1 cursor-pointer"
                    icon="Edit2Icon"
                    @click="changeStatusDispatch = !changeStatusDispatch"
                  />
                  <div v-if="changeStatusDispatch" class="mt-1">
                    <b-row>
                      <b-col
                        md="7"
                        sm="12"
                        xs="12"
                      >
                        <v-select
                          v-model="data.item.dispatch"
                          :options="dispatchStatusOptions"
                          :clearable="false"
                        />
                      </b-col>
                      <b-col
                        md="5"
                        sm="12"
                        xs="12"
                      >
                        <v-select
                          v-if="['Envoyé depuis boutique', 'Pris en boutique'].includes(data.item.dispatch)"
                          v-model="specificOptionDispatch"
                          :options="dispatchStatusOptionsDispatch"
                          :clearable="false"
                        />
                      </b-col>
                    </b-row>
                    <div class="d-flex align-items-center">
                      <b-button
                        variant="text"
                        @click="changeStatusDispatch = false"
                      >
                        <small>Annuler</small>
                      </b-button>
                      <b-button
                        :disabled="['Envoyé depuis boutique', 'Pris en boutique'].includes(data.item.dispatch) && !specificOptionDispatch"
                        variant="text"
                        class="text-warning"
                        @click="updateOrder('dispatch-status', data.item)"
                      >
                        <small>Enregistrer</small>
                      </b-button>
                    </div>
                  </div>
                  <br>
                  <span class="text-primary cursor-pointer font-weight-bolder" @click="showLogisticModal(data.item.sku)"><strong><u>SKU:</u></strong> {{ order.system && order.system.items && order.system.items.length && order.system.items.find(i => i.id === data.item.id) ? order.system.items.find(i => i.id === data.item.id).sku : data.item.sku }}</span>
                  <br>
                  <span
                    v-for="(meta, key) of data.item.meta_data"
                    :key="key"
                  >
                    <span v-if="meta.value"><strong><u>{{ meta.key }}:</u></strong> {{ meta.value }}</span>
                    <br>
                  </span>
                  <span v-if="data.item.size">Taille: {{ data.item.size }}</span>
                  <br v-if="data.item.lattes">
                  <span v-if="data.item.lattes"><strong>Lattes:</strong> {{ data.item.lattes }}</span>
                  <br v-if="data.item.color">
                  <span v-if="data.item.color">{{ (data.item.color) }}</span>
                  <br v-if="data.item['tete-de-lit']">
                  <span v-if="data.item['tete-de-lit']"><strong>Tête:</strong> {{ data.item['tete-de-lit'] }}&nbsp;</span>

                  <span
                    v-if="data.item.log && data.item.cost"
                  >
                    <strong><u>LOG:</u> </strong> {{ data.item.log.toFixed(2) }}€ / <strong><u>COST:</u> </strong>{{ data.item.cost.toFixed(2) }}€  / <strong><u>COS:</u> </strong> {{ ((Number(data.item.total)) * 0.22).toFixed(0) }}€ / <strong><u>Marge:</u> </strong>{{  ((Number(data.item.total)) - data.item.cost - data.item.log - ((Number(data.item.total)) * 0.22)).toFixed(0) }}€
                  </span>
                  <br>
                  <b-badge
                    v-if="data.item.sinistre"
                    pill
                    :variant="'primary'"
                    class="mt-1"
                  >
                    Sinitre déclaré le: {{ $moment(data.item.dateSinistre).format('DD/MM/YYYY') }} ({{ data.item.sinistre }})
                  </b-badge>
                  <b-badge
                    v-else
                    pill
                    :variant="'primary'"
                    class="mt-1 cursor-pointer"
                    @click="openSinistre(data.item)"
                  >
                    Déclarer sinistre
                  </b-badge>
                </b-card-text>
                <div v-if="data.item.meta_data && data.item.meta_data.length > 1">
                  <b-card-text
                    v-if="data.item.meta_data.find(m => m.key === 'free_gift_coupon')"
                    class="text-nowrap mb-0"
                  >
                    <strong>Code promo:</strong> {{ data.item.meta_data.find(m => m.key === 'free_gift_coupon').value }}
                  </b-card-text>
                  <b-card-text
                    v-if="data.item.meta_data.find(m => m.key === 'free_product')"
                    class="text-nowrap"
                  >
                    <strong>Produit gratuit</strong>
                  </b-card-text>
                </div>
              </template>

              <template #cell(ht)="data">
                <span v-if="isDuplicated">{{ (Number(data.item.price) - Number(data.item.taxe)).toFixed(2) }}€</span>
                <span v-else>{{ Number(data.item.subtotal).toFixed(2) || Number(data.item.total).toFixed(2) }}€</span>
              </template>
              <template #cell(tva)="data">
                <span v-if="isDuplicated">{{ Number(data.item.taxe).toFixed(2) }}€</span>
                <span v-else>{{ (Number(data.item.subtotal_tax)).toFixed(2) }}€</span>
              </template>
              <template #cell(total)="data">
                <b-card-text class="font-weight-bold mb-25 row items-center">
                  <div class="col-6">
                    <span v-if="isDuplicated">{{ (Number(data.item.price)).toFixed(2) }}€</span>
                    <span v-else>
                      {{ data.item.subtotal && data.item.subtotal_tax ? (Number(data.item.subtotal) + Number(data.item.subtotal_tax)).toFixed(2) || Number(data.item.price).toFixed(2) : (Number(data.item.total) + Number(data.item.total_tax)).toFixed(2) || Number(data.item.price).toFixed(2) }}€
                    </span>
                  </div>
                  <div class="col">
                    <feather-icon
                      v-if="isDuplicated"
                      icon="TrashIcon"
                      class="ml-5 text-primary"
                      style="cursor: pointer;"
                      size="20"
                      @click="remove(data.item.variationId)"
                    />
                    <div v-else-if="['pending', 'waiting-delivery', 'processing'].includes(order.status) && !isDuplicated && data.item.dispatch !== 'Dispatché'" class="d-flex">
                      <feather-icon
                        icon="EditIcon"
                        class="ml-5 text-primary"
                        style="cursor: pointer;"
                        size="20"
                        @click="selectedProduct = data.item; addProduct = true"
                      />
                      <feather-icon
                        icon="TrashIcon"
                        class="ml-5 text-primary"
                        style="cursor: pointer;"
                        size="20"
                        @click="selectedProduct = data.item; changeProduct({}, true)"
                      />
                    </div>
                  </div>
                </b-card-text>
              </template>
            </b-table-lite>
            <b-row v-if="order.fee_lines && order.fee_lines.length">
              <b-col
                cols="11"
                md="11"
              >
                <div v-for="(fee, key) of order.fee_lines" :key="key" class="container d-flex justify-content-between">
                  <span>{{ fee.name }} </span>
                  <span>{{ fee.total }}€ </span>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="isDuplicated">
              <b-col
                cols="11"
                md="11"
                class="d-flex justify-content-end"
              >
                <b-button
                  variant="primary"
                  @click="addProduct = !addProduct"
                >
                  <span class="align-middle">Ajouter un produit</span>
                </b-button>
              </b-col>
            </b-row>
            <b-col
              v-if="isDuplicated"
              cols="11"
              class="mt-2"
            >
              <b-row
                v-for="(value, keyFee) of fee_lines"
                :key="keyFee"
                class="d-flex align-items-center"
              >
                <b-col
                  cols="3"
                  class="primary my-4"
                >
                  <p>Titre:</p>
                  <b-form-input
                    v-if="value"
                    v-model="value.name"
                    class="mr-4"
                    style="width: 100%"
                  />
                </b-col>
                <b-col
                  cols="8"
                  class="primary my-4"
                >
                  <p>Montant (HT):</p>
                  <b-form-input
                    v-if="value"
                    v-model="value.total"
                    class="mr-4"
                    style="width: 100%"
                  />
                </b-col>
                <b-col
                  cols="1"
                  class="primary my-4"
                >
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    @click="fee_lines.splice(keyFee, 1);"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-col
                span="12"
                class="primary d-flex justify-content-end"
              >
                <b-button
                  variant="primary"
                  @click="fee_lines.push({ name: '', total: 0 })"
                >
                  <span class="align-middle">Ajouter une ligne de frais</span>
                </b-button>
              </b-col>
            </b-col>
            <div
              v-if="isDuplicated"
              class="h4 mb-3 ml-3"
            >
              Coupon de réduction
            </div>
            <b-row
              v-if="isDuplicated"
              class="d-flex align-items-center mb-2 ml-3"
            >
              <b-col
                md="4"
              >
                <b-form-input
                  v-model="coupon"
                  class="d-inline-block mr-1"
                  placeholder="Code promo...."
                  @keyup.native.enter="checkCoupon"
                />
              </b-col>
              <b-col
                md="4"
              >
                <b-button
                  v-if="coupon && coupon !== ''"
                  :disabled="loading"
                  variant="primary"
                  @click="checkCoupon"
                >
                  Vérifier le coupon
                </b-button>
              </b-col>
              <b-col
                v-if="amount > 0"
                md="12"
                class="mt-2"
              >
                <span>Ce coupon de réduction vous permet d'économiser <strong>{{ amount }}€</strong></span>
              </b-col>
              <b-col
                v-if="coupon && (coupon.toLowerCase() === 'gesteco2020' || coupon.toLowerCase() === 'savvoucher2025')"
                md="6"
                class="mt-2"
              >
                <p>Motif obligatoire:</p>
                <v-select
                  v-model="motif"
                  :options="gestecoOptions"
                  :clearable="false"
                  @input="order.customer_note = motif"
                />
              </b-col>
            </b-row>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>

                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3"
                  order="2"
                  order-md="1"
                >
                  <b-card-text
                    v-if="!isDuplicated && order.coupon_lines.length"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">Code promo:</span>
                    <ul>
                      <li
                        v-for="(coupon, key) of order.coupon_lines"
                        :key="key"
                      >
                        <span class="ml-75"><strong>{{ coupon.code }}</strong> : Remise de {{ (Number(coupon.discount) + Number(coupon.discount_tax)).toFixed(2) }}€</span>
                      </li>
                    </ul>
                  </b-card-text>
                  <b-card-text
                    v-if="!isDuplicated"
                    class="mb-0"
                  >
                    <span class="font-weight-bold">Livraison:</span>
                    <ul>
                      <li
                        v-for="(shipping, key) of order.shipping_lines"
                        :key="key"
                      >
                        <span class="ml-75"><strong>{{ shipping.method_title }}</strong> : {{ shipping.total }}€</span>
                      </li>
                    </ul>
                  </b-card-text>
                  <b-card-text
                    v-if="!isDuplicated && order.system && order.system.dateShipping"
                    class="mb-0"
                  >
                    <span class="font-weight-bold"><strong><u>Date de livraison décalée:</u></strong> {{ $moment(order.system.dateShipping).format('DD-MM-YYYY') }}</span>
                  </b-card-text>
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div
                    v-if="!isDuplicated"
                    class="invoice-total-wrapper"
                  >
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total (HT):
                      </p>
                      <p class="invoice-total-amount">
                        {{ (Number(order.total) - Number(order.total_tax)).toFixed(2) }} €
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Livraison:
                      </p>
                      <p class="invoice-total-amount">
                        {{ (Number(order.shipping_total) - Number(order.shipping_tax)).toFixed(2) }} €
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        TVA:
                      </p>
                      <p class="invoice-total-amount">
                        {{ Number(order.total_tax).toFixed(2) }} €
                      </p>
                    </div>
                    <div
                      v-if="order.discount_total !== '0.00'"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Sous-total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ (Number(order.total)+(Number(order.discount_total) + Number(order.discount_tax))).toFixed(2) }} €
                      </p>
                    </div>
                    <div
                      v-if="order.discount_total !== '0.00'"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Réduction:
                      </p>
                      <p class="invoice-total-amount">
                        - {{ (Number(order.discount_total) + Number(order.discount_tax)).toFixed(2) }} €
                      </p>
                    </div>
                    <div
                      v-if="order.refunds.length > 0"
                      class="invoice-total-item"
                    >
                      <p class="invoice-total-title">
                        Remboursement:
                      </p>
                      <p class="invoice-total-amount">
                        {{ order.refunds.map(r => Number(r.total)).reduce((a, b) => a + b, 0).toFixed(2) }} €
                      </p>
                    </div>
                    <hr class="my-50">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">
                        {{ (Number(order.total) + order.refunds.map(r => Number(r.total)).reduce((a, b) => a + b, 0)).toFixed(2) }} €
                      </p>
                    </div>
                    <div v-if="order.cogs">
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <strong>COGS:</strong>
                        </p>
                        <p class="invoice-total-amount">
                          {{ order.cogs.cost.toFixed(2) }} €
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <strong>LOG:</strong>
                        </p>
                        <p class="invoice-total-amount">
                          {{ order.cogs.log.toFixed(2) }} €
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <strong>COS:</strong>
                        </p>
                        <p class="invoice-total-amount">
                          {{ ((Number(order.total) - Number(order.total_tax)) * 0.22).toFixed(2)}} €
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          <strong>MARGE:</strong>
                        </p>
                        <p class="invoice-total-amount">
                          {{ (((Number(order.total) - Number(order.total_tax))) - ((Number(order.total) - Number(order.total_tax)) * 0.22) - order.cogs.log - order.cogs.cost).toFixed(2)}} €
                          <br>
                          {{ (((((Number(order.total) - Number(order.total_tax))) - ((Number(order.total) - Number(order.total_tax)) * 0.22) - order.cogs.log - order.cogs.cost) / ((Number(order.total) - Number(order.total_tax))) * 100)).toFixed(1)}} %
                        </p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        <strong>Total:</strong>
                      </p>
                      <p class="invoice-total-amount">
                        {{ price.toFixed(2) }} €
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="isDuplicated">
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    variant="primary"
                    :disabled="loading"
                    @click="createOrder"
                  >
                    <span class="align-middle">Créer la commande</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <span v-if="!isDuplicated">{{ order.customer_note }}</span>
              <b-form-textarea
                v-else-if="!coupon || (coupon.toLowerCase() !== 'gesteco2020' && coupon.toLowerCase() !== 'savvoucher2025')"
                id="event-description"
                v-model="order.customer_note"
                class="mb-1"
              />
              <span v-else>{{ order.customer_note }}</span>
            </b-card-body>
            <b-card-body v-if="order.refunds" class="invoice-padding pt-0">
              <span class="font-weight-bold">Remboursements: </span>
              <ul>
                <li v-for="(refund, keyR) of order.refunds" :key="keyR">
                  {{ refund.reason }}: {{ refund.total }}
                </li>
              </ul>
            </b-card-body>
          </b-card>
        </b-col>

      </b-row>
      <b-row
        v-if="claims && claims.length && !isDuplicated"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25 mr-25">
                  Claims
                </b-card-title>
                <a :href="`${$route.path}/claims`">

                  <b-button
                    variant="text"
                    class="text-warning"
                  >
                    <small>Voir les claims</small>
                  </b-button>
                </a>
              </div>
            </b-card-header>
            <b-table-lite
              v-if="claims && claims.length"
              responsive
              :items="claims"
              :fields="fieldsClaim"
              class="mt-2"
            >
              <template #cell(_order)="data">
                <a :href="`/commande/${data.item._order.order.number}`" target="_blank">{{ data.item._order.order.number }}</a>
              </template>
              <template #cell(dueDate)="data">
                <b-badge :variant="`light-${$moment(data.item.dueDate).diff($moment()) < 2 ? 'danger' : 'success'}`">
                  {{ $moment(data.item.dueDate).format('DD/MM/YYYY') }}
                </b-badge>
              </template>
              <template #cell(_principalAssignee)="data">

                <b-badge :variant="`light-success`">
                  {{ data.item._principalAssignee.lastName }}
                </b-badge>
                <b-badge v-for="(user, key) of data.item._otherAssignees" :key="key" :variant="`light-secondary`" class="ml-1">
                  {{ user.lastName }}
                </b-badge>
              </template>
            </b-table-lite>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="order && !isDuplicated && order.status !== 'failed' && order.status !== 'cancelled'"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          md="6"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Timeline
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline v-if="order.notes && order.notes.length">
                <div
                  v-for="(h, key) of order.notes"
                  :key="key"
                >
                  <app-timeline-item
                    class="mt-2"
                    :title="h.note"
                    :subtitle="`Fait par ${h.author}`"
                    :time="$moment(h.date_created).format('hh:mm / DD-MM-YYYY')"
                    variant="danger"
                  />
                </div>
              </app-timeline>
              <h3 v-if="order.sav && order.sav.historic && order.sav.historic.length">
                SAV Timeline
              </h3>
              <app-timeline v-if="order.sav && order.sav.historic && order.sav.historic.length">
                <div
                  v-for="(h, key) of order.sav.historic"
                  :key="key"
                >
                  <app-timeline-item
                    class="mt-2"
                    :title="h.text"
                    :subtitle="h.user"
                    :time="$moment(h.created_at).format('DD-MM-YYYY')"
                    variant="danger"
                  />
                </div>
              </app-timeline>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          md="6"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Commentaire
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline v-if="order.sav && order.sav.comments && order.sav.comments.length">
                <div
                  v-for="(c, keyC) of order.sav.comments"
                  :key="keyC"
                >
                  <app-timeline-item
                    class="mt-2"
                    :title="c.text"
                    :subtitle="c.user"
                    :time="$moment(c.created_at).format('DD-MM-YYYY')"
                    variant="success"
                  />
                  <b-button
                    v-if="c._user === me._id"
                    variant="text"
                    class="text-warning"
                    @click="deleteComment(keyC)"
                  >
                    <small>Supprimer</small>
                  </b-button>
                </div>
              </app-timeline>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="order && order.trackings && order.trackings.length && !isDuplicated"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25 mr-25">
                  Suivi de livraison
                </b-card-title>
              </div>
              <div v-if="order.trackings.filter(t => t.trackingLink !== null).length && order.trackings.find(t => t.carrier === 'agediss' && t.trackingLink && t.trackingLink !== '')">
                <a
                  :href="`https://espace-client.agediss.com/livraisons/${order.trackings.find(t => t.carrier === 'agediss' && t.trackingLink && t.trackingLink !== '').trackingNumber}`"
                  target="_blank"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                  >
                    <span class="align-middle">Dashboard Agediss</span>
                  </b-button>
                </a>
                <a
                  :href="order.trackings.find(t => t.carrier === 'agediss' && t.trackingLink && t.trackingLink !== '').trackingLink"
                  target="_blank"
                >
                  {{ order.trackings.find(t => t.carrier === 'agediss' && t.trackingLink && t.trackingLink !== '').trackingLink }}
                </a>
              </div>
              <a
                v-else-if="order.trackings.filter(t => t.trackingLink !== null && t.trackingLink !== 'agediss').length"
                :href="order.trackings.filter(t => t.trackingLink !== null && t.trackingLink !== 'agediss')[0].trackingLink"
                target="_blank"
              >
                {{ order.trackings.find(t => t.trackingLink !== null && t.trackingLink !== 'agediss').trackingLink }}
              </a>
            </b-card-header>
            <p class="ml-2" v-if="order.trackings.length && order.trackings.filter(t => t.carrier === 'agediss') && order.trackings.filter(t => t.carrier === 'agediss').length">AGEDISS</p>
            <b-badge class="mb-2 px-4" variant="primary" v-if="order.trackings.length && order.trackings.filter(t => t.carrier === 'agediss') && order.trackings.filter(t => t.carrier === 'agediss').length && order.agedissRDV">Rdv de livraison pris pour le {{ order.agedissRDV }}</b-badge>
            <b-table-lite
              v-if="order.trackings.length && order.trackings.filter(t => t.carrier === 'agediss') && order.trackings.filter(t => t.carrier === 'agediss').length"
              responsive
              :items="order.trackings.filter(t => t.carrier === 'agediss')"
              :fields="['date', 'orderNumber', 'name', 'postalCode', 'type', 'note', 'id', { key: 'custom1', label: 'Lien suivi (externe)' }]"
            >
              <template #cell(date)="data">
                {{ $moment(new Date(data.item.date)).format('DD/MM/YYYY hh:mm') }}
              </template>
              <template #cell(custom1)="data">
                <a v-if="data.item.custom1 !== ''" :href="`${data.item.custom1.length > 15 ? `https://wwwapps.ups.com/WebTracking/track?loc=en_EN&trackNums=${data.item.custom1}&requester=ST/trackdetails` : order.shipping.country === 'FR' ? 'FR' : 'GROUP' ? `https://gls-group.eu/GROUP/en/parcel-tracking?match=${data.item.custom1}` : `https://gls-group.eu/FR/en/suivi-colis.html?match=${data.item.custom1}` }`" target="_blank" class="cursor-pointer text-warning">{{ data.item.custom1 }}</a>
              </template>
            </b-table-lite>
            <b-row>
              <b-col
                md="3"
                sm="3"
                xs="3"
              >
                <b-button
                  v-if="order.itemsLogistics.length && order.itemsLogistics.filter(t => t.logistic === 'CAR').length"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  @click="showGRCColisland = true"
                >
                  <span class="align-middle">Claim Colisland</span>
                </b-button>
              </b-col>
              <b-col
                v-if="order.trackings.length && order.trackings.filter(t => t.dashboardLink).length"
                md="3"
                sm="3"
                xs="3"
              >
                <a
                  :href="order.trackings.find(t => t.dashboardLink).dashboardLink"
                  target="_blank"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="mb-4"
                  >
                    <span class="align-middle">Dashboard Colisland</span>
                  </b-button>
                </a>
              </b-col>
            </b-row>
            <b-table-lite
              v-if="order.trackings.length && order.trackings.filter(t => t.carrier !== 'agediss') && order.trackings.filter(t => t.carrier !== 'agediss').length"
              responsive
              :items="order.trackings.filter(t => t.carrier !== 'agediss')"
              :fields="['trackingNumber', 'carrier', { key: 'items', label: 'Contenu du colis' }, { key: 'lastEvent', label: 'Last Event' }]"
              class="mt-2"
            >
              <template #cell(trackingNumber)="data">
                <a v-if="data.item.trackingLink" :href="data.item.trackingLink" target="_blank" class="cursor-pointer text-warning">{{ data.item.trackingNumber }}</a>
                <a v-else-if="!['COL', 'UPS', 'BRT', 'COL_6A', 'COL_CF'].includes(data.item.carrier)" :href="`https://track.colisland.com/b2c.php?code=${data.item.trackingNumber.replace('B2C-', '')}&site_id=50`" target="_blank" class="cursor-pointer text-warning">{{ data.item.trackingNumber }}</a>
                <a v-else-if="['COL', 'COL_6A', 'COL_CF'].includes(data.item.carrier)" :href="`https://www.laposte.fr/outils/suivre-vos-envois?code=${data.item.trackingNumber.replace('B2C-', '')}`" target="_blank" class="cursor-pointer text-warning">{{ data.item.trackingNumber }}</a>
                <span v-else :id="data.item.trackingNumber" @click="getTracking(data.item.trackingNumber)" class="cursor-pointer text-warning">{{ data.item.trackingNumber }}</span>
              </template>
              <template #cell(items)="data">
                <p v-if="data.item.items && data.item.items.length">{{ data.item.items.map(i => `${i.sku} x ${i.qty}`).join('/') }}</p>
                <p v-else>Inconnu</p>
              </template>
            </b-table-lite>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="order && order.system.ships && order.system.ships.length && !isDuplicated"
        class="invoice-preview"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25 mr-25">
                  Suivi de livraison ( UPS / GLS / BRT)
                </b-card-title>
              </div>
            </b-card-header>
            <b-table-lite
              v-if="order.system.ships.length"
              responsive
              :items="order.system.ships"
              :fields="[{key: 'tracking_link', label: 'TRACKING NUMBER'}, 'carrier', {key: 'url', label: 'PDF LINK'}, 'items']"
              class="mt-2"
            >
              <template #cell(url)="data">
                <a v-if="!Array.isArray(data.item.url)" :href="data.item.url" target="_blank">Link</a>
                <div v-else>
                  <a
                    v-for="(t, keyT) of data.item.url"
                    :key="keyT"
                    :href="t"
                    target="_blank">Link {{ keyT + 1 }} <br></a>
                </div>
              </template>
              <template #cell(tracking_link)="data">
                <a :href="data.item.tracking_link" target="_blank">Tracking link</a>
              </template>
              <template #cell(items)="data">
                <span v-if="order.system && order.system.items && order.system.items.filter(i => i.tracking === data.item.tracking_number).length > 0">{{ order.system.items.filter(i => i.tracking === data.item.tracking_number).map(t => t.name).join(' / ') }}</span>
              </template>
            </b-table-lite>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-if="gorgiasTicket"
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Ticket Gorgias
                </b-card-title>
              </div>
            </b-card-header>
            <GorgiasView v-if="gorgiasTicket && showGorgiasModal" :ticket="gorgiasMessages" @close="showGorgiasModal = false" />
            <!-- timeline -->
            <b-card-body>
              <b-table
                small
                :fields="['via', 'status',{ key:'last_message_datetime', label: 'Last Message' }, 'subject', 'messages_count', { key: 'assignee_user.name', label: 'SAV' }, 'uri']"
                :items="gorgiasTicket"
                responsive="sm"
                @row-clicked="e => getTicket(e.id)"
              >
                <template #cell(last_message_datetime)="data">
                  {{ $moment(data.item.last_message_datetime).format('DD/MM/YYYY') }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                    pill
                    :variant="data.item.status === 'closed' ? 'success' : 'primary'"
                  >
                    {{ data.item.status }}
                  </b-badge>
                </template>
                <template #cell(via)="data">
                  <img v-if="['email', 'gorgias_chat'].includes(data.item.via)" class="cursor-pointer" :src="require(`@/assets/images/gorgias/${data.item.via}.png`)" style="height: 30px;" />
                  <p v-else>{{ data.item.via }}</p>
                </template>
                <template #cell(uri)="data">
                  <a :href="`https://kipli.gorgias.com/app/ticket/${data.item.id}`" target="_blank">Lien Gorgias</a>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="order.calls && order.calls.length"
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Appel Ringover
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <b-table
                small
                :fields="fieldCall"
                :items="order.calls"
                responsive="sm"
              >
                <!-- A virtual column -->
                <template #cell(type)="data">
                  <b-badge
                    pill
                    variant="secondary"
                  >
                    {{ data.item.type }}
                  </b-badge>
                </template>

                <template #cell(direction)="data">
                  <feather-icon
                    size="24"
                    :style="data.item.direction === 'out' ? 'color: red;' : 'color: green;'"
                    :icon="data.item.direction === 'out' ? 'PhoneOutgoingIcon' : 'PhoneIncomingIcon'"
                  />
                </template>
                <!-- A custom formatted column -->
                <template #cell(start_time)="data">
                  {{ $moment(data.item.start_time).format('DD/MM/YYYY') }}
                </template>

                <template #cell(total_duration)="data">
                  {{ data.item.total_duration }} sec.
                </template>

                <template #cell(last_state)="data">
                  <b-badge
                    pill
                    variant="primary"
                  >
                    {{ data.item.last_state }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(record)="data">
                  <a :href="data.item.record || data.item.voicemail" targer="_blank">{{ data.item.record || data.item.voicemail ? 'Ecouter l\'appel' : 'Pas d\'enregistrement' }}</a>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="order.events"
          md="6"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Events (Klaviyo)
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline v-if="order.events">
                <div
                  v-for="(event, key) of order.events"
                  :key="key"
                >
                  <div v-if="event && event.event_name && (key < 5 || showAllEventKlavio)">
                    <app-timeline-item
                      class="mt-2 mb-2"
                      :title="`${event.event_name}`"
                      :subtitle="`${event.event_name.includes('Email') ? event.event_properties['Subject'] : event.event_name.includes('Subscribed') ? event.event_properties['List'] : event.event_name.includes('Active on Site') ? event.event_properties['page'] : ['Viewed Product', 'Ordered Product'].includes(event.event_name) ? event.event_properties['ProductName'] : ['Added to Cart'].includes(event.event_name) ? event.event_properties['AddedItemProductName'] : ''}`"
                      :time="$moment(new Date(event.datetime)).fromNow()"
                      :variant="`${event.event_name.includes('Email') ? 'danger' : event.event_name.includes('Subscribed') ? 'primary' : event.event_name.includes('Active on Site') ? 'warning' : 'success'}`"
                    />
                    <span v-b-toggle="`collapse-${key}`" class="text-primary">
                      Plus de détail
                    </span>
                    <b-collapse :id="`collapse-${key}`" class="mt-2">
                      <p v-for="(propertie, keyP) of Object.entries(event.event_properties)" :key="keyP">
                        <strong><u>{{ propertie[0] }}:</u></strong> {{ propertie[1]}}
                      </p>
                    </b-collapse>
                  </div>
                </div>
              </app-timeline>
              <p @click="showAllEventKlavio = true" class="text-primary">Voir tout</p>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="order.system && order.system.sources"
          md="6"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Sources ( Analytics UTM )
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <app-timeline v-if="order.system && order.system.sources">
                <div
                  v-for="(h, key) of order.system.sources"
                  :key="key"
                >
                  <app-timeline-item
                    v-if="h && h.source"
                    class="mt-2"
                    :title="`${key + 1} - ${h.source}`"
                    :subtitle="`Campagne: ${h.campaign}`"
                    :time="h.medium"
                    variant="danger"
                  />
                </div>
              </app-timeline>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="order && order.system && order.system.tickets"
          md="6"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Justificatif
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <div v-for="(src, keyI) of order.system.tickets" :key="keyI">
                <img :src="src.url" style="width: 60vh;">
                <p>Le {{ $moment(src.date).format('DD/MM/YYYY') }}</p>
              </div>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          v-if="order.deliveries && order.deliveries.length"
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Coût Logistique
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <b-table-lite
                small
                :fields="['type', 'reference', 'orderNumber', 'total', 'label']"
                :items="order.deliveries"
                responsive="sm"
              ></b-table-lite>
              <!--/ timeline -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        v-if="order && !isDuplicated && order.system && order.system.return"
        class="invoice-preview mb-6"
      >

        <!-- Col: Left (Invoice Container) -->
        <b-col
          md="12"
          sm="12"
          xs="12"
        >
          <b-card no-body>
            <b-card-header>
              <div class="d-flex align-items-center">
                <feather-icon
                  size="24"
                  class="mr-1"
                  icon="ListIcon"
                />
                <b-card-title class="ml-25">
                  Retour Client
                </b-card-title>
              </div>
            </b-card-header>

            <!-- timeline -->
            <b-card-body>
              <b-table-lite
                responsive
                :items="order.system.return"
                :fields="['name', 'status', { key: 'url', label: 'Etiquette' }, 'carrier', 'tracking_link', 'tracking_number']"
                class="mt-2"
              >
                <template #cell(url)="data">
                  <div v-if="Array.isArray(data.item.url)">
                    <a :href="data.item.url.find(u => u.includes('return'))" target="_blank">ETIQUETTE</a>
                  </div>
                  <a v-else :href="data.item.url" target="_blank">ETIQUETTE</a>
                </template>
                <template #cell(carrier)="data">
                  <img v-if="data.item.carrierLogo" :src="data.item.carrierLogo" style="width: 90px;" />
                  <p v-else> {{ data.item.carrier }} </p>
                </template>
                <template #cell(tracking_link)="data">
                  <a :href="data.item.tracking_link" target="_blank">SUIVI</a>
                </template>
              </b-table-lite>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card
        v-if="order && order.cogs && order.cogs.official && order.cogs.official.length && !isDuplicated"
      >
        <b-card-header>
          <div class="d-flex align-items-center">
            <feather-icon
              size="24"
              class="mr-1"
              icon="ListIcon"
            />
            <b-card-title class="ml-25">
              Frais de livraison
            </b-card-title>
          </div>
        </b-card-header>
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            md="12"
            sm="12"
            xs="12"
          >
            <b-table-lite
              responsive
              :items="order.cogs.official"
              :fields="['initialNumber', 'shipper', 'cost', 'type', 'locality', 'product', 'description', 'comment']"
            >
              <template #cell(cost)="data">
                <p>{{ data.item.cost }}€</p>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="order && order.orders && order.orders.length && !isDuplicated"
      >
        <b-card-header>
          <div class="d-flex align-items-center">
            <feather-icon
              size="24"
              class="mr-1"
              icon="ListIcon"
            />
            <b-card-title class="ml-25">
              Autres commandes du client
            </b-card-title>
          </div>
        </b-card-header>
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            md="12"
            sm="12"
            xs="12"
          >
            <b-table-lite
              responsive
              :items="order.orders"
              :fields="['date_created', 'number', 'total', 'items', 'status']"
            >
              <template #cell(date_created)="data">
                <p>{{ $moment(data.item.date_created).locale('fr').format('DD MMMM YYYY') }}</p>
              </template>
              <template #cell(number)="data">
                <a :href="`/commande/${data.item.number}`" target="_blank">{{ data.item.number }}</a>
              </template>
              <template #cell(total)="data">
                <p>{{ data.item.total }}€</p>
              </template>
              <template #cell(items)="data">
                <p>{{ data.item.line_items.map(i => `${i.name} x ${i.quantity}`).join(' / ') }}</p>
              </template>
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>
            </b-table-lite>
          </b-col>
        </b-row>
      </b-card>

      <order-sidebar-informations
        id="order-sidebar-informations"
        :user="order.shipping"
        title="information de livraison"
        type="shipping"
        :is-new="isDuplicated"
        @update="getOrder"
        @update-new="e => order.shipping = e"
      />
      <order-sidebar-informations
        id="order-sidebar-informations-billing"
        :user="order.billing"
        title="information de facturation"
        type="billing"
        :is-new="isDuplicated"
        @update="getOrder"
        @update-new="e => order.billing = e"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import {
  BRow, BCol, BCard, BSpinner, BCardBody, BFormFile, BFormInput, BTableLite, BCardText, BBadge, BButton, VBToggle, BCardHeader, BCardTitle, BFormTextarea, BDropdown, BDropdownItem, BCollapse, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import OrderSidebarInformations from '@core/components/order/OrderSidebarInformations.vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import Products from '@/components/encaissement/Products.vue'
import products from '@/utils/products'
import vSelect from 'vue-select'
import Defective from '@/components/orders/Defective.vue'
// import Claim from '@/components/orders/Claim.vue'
import Return from '@/components/orders/Return.vue'
import GeneratePrint from '@/components/shippypro/GeneratePrint.vue'
import LogisticList from '@/components/logistic/LogisticList.vue'
import GorgiasView from '@/components/chat/GorgiasView.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardBody,
    BTableLite,
    BCardText,
    BBadge,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    AppTimeline,
    AppTimelineItem,
    BFormFile,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
    BCollapse,
    BSpinner,
    OrderSidebarInformations,
    Products,
    GeneratePrint,
    Defective,
    // Claim,
    Return,
    GorgiasView,
    vSelect,
    flatPickr,
    LogisticList,
  },
  data() {
    return {
      file: null,
      claims: [],
      splitPayments: [{ type: null, value: 0 }, { type: null, value: 0 }],
      gorgiasTicket: null,
      gorgiasMessages: null,
      selectedProduct: null,
      showGRCColisland: false,
      loading: false,
      order: null,
      addProduct: false,
      isDuplicated: false,
      showComment: false,
      comment: '',
      coupon: '',
      almaLink: '',
      showLink: false,
      showChangeDate: false,
      openModalJustificatif: false,
      openModalSplitPayment: false,
      openModalSinistre: false,
      sinistre: null,
      productSinistre: null,
      showAllEventKlavio: false,
      fee_lines: [],
      status: 'pending',
      statusOptions: ['processing', 'pending', 'on-hold', 'completed', 'sended', 'delete', 'cancelled', 'refunded', 'failed', 'waiting-delivery', 'retail-stock'],
      changeStatus: false,
      changeStatusDispatch: false,
      dispatchStatusOptions: ['Dispatché', 'Non dispatché', 'Annulé', 'Envoyé depuis boutique', 'Pris en boutique'],
      dispatchStatusOptionsDispatch: ['PARIS', 'LYON', 'TOULOUSE'],
      specificOptionDispatch: '',
      motif: null,
      fieldsClaim: [{ key: 'descriptionType', label: 'Claim', sortable: true }, { key: 'subDescriptionType', label: 'Provider', sortable: true }, { key: 'statusType', label: 'Status', sortable: true }, { key: 'dueDate', label: 'Due Date', sortable: true }, { key: '_principalAssignee', label: 'Operator', sortable: true }],
      gestecoOptions: ['Problème de livraison - Colis Perdu', 'Problème de livraison - Colis Non Perdu', 'Remplacement commande', 'Offre mal appliqué', 'Geste commercial', 'Article défectueux'],
      fieldCall: [
        // A virtual column that doesn't exist in items
        'type',
        'direction',
        // A column that needs custom formatting
        { key: 'last_state', label: 'Status' },
        { key: 'start_time', label: 'Date' },
        // A regular column
        { key: 'start_time', label: 'Début d\'appel' },
        { key: 'total_duration', label: 'Durée' },
        // A regular column
        { key: 'record', label: 'Enreg.' },
        // A virtual column made up from two fields
        { key: 'user.firstname', label: 'SAV' },
      ],
      countries: ['FR', 'DE', 'IT', 'NL', 'ES', 'PT', 'EN', 'DK'],
      showLogModal: false,
      selectSkuLog: null,
      showGorgiasModal: false,
    }
  },
  computed: {
    ...mapGetters('admin', ['me']),
    ...mapGetters('cart', ['items', 'discount', 'price']),
  },
  async mounted() {
    const track = document.createElement('script')
    track.setAttribute('src', 'https://www.17track.net/externalcall.js')
    document.head.appendChild(track)
    this.get()
    await this.getOrder()
    await this.getClaims()
    this.getGorgiasInfo()
    console.log(this.order)
  },
  methods: {
    ...mapActions('admin', ['get']),
    ...mapActions('cart', ['add', 'reset', 'remove', 'addDiscount']),
    getStockStatus(item) {
      return this.order.products.find(i => i._id === item?.productId)?.variations?.find(v => v.id === item?.variationId)?.stock_status
    },
    getTrackingLink(item) {
      let carrier = item.logistic?.toLowerCase()
      if (carrier && this.order?.trackings?.length) {
        if (carrier === 'car') {
          // if (item.shipper === 'B2C') {
          //   carrier = 'b2cpp'
          //   return `https://track.colisland.com/b2c.php?code=${this.order.trackings?.find(t => t.carrier?.toLowerCase() === carrier)?.trackingNumber}&site_id=50`
          // }
          carrier = 'col_6a'
          return `https://www.laposte.fr/outils/suivre-vos-envois?code=${this.order.trackings?.find(t => t.carrier?.toLowerCase() === carrier)?.trackingNumber}`
        }
        if (item.shipper === 'GLS') {
          carrier = 'glsfrance'
        }
        if (item.shipper === 'UPS') {
          carrier = 'ups'
        }
        return this.order.trackings?.find(t => t.carrier.toLowerCase() === carrier)?.trackingLink
      }
      return null
    },
    getLastEvent(item) {
      let carrier = item.logistic?.toLowerCase()
      if (carrier && this.order?.trackings?.length) {
        if (carrier === 'car') {
          carrier = 'col_6a'
          return this.order.trackings?.find(t => t.carrier.toLowerCase() === carrier)?.lastEvent
        }
        if (carrier === 'agediss' && item.shipper === 'GLS') {
          carrier = 'glsfrance'
          return this.order.trackings?.find(t => t.carrier.toLowerCase() === carrier)?.lastEvent
        }
        if (item.shipper === 'UPS') {
          carrier = 'ups'
          return this.order.trackings?.find(t => t.carrier.toLowerCase() === carrier)?.lastEvent?.slice(0, 35)
        }
        return this.order.trackings?.filter(t => t.carrier.toLowerCase() === carrier)[0]?.note
      }
      return null
    },
    getDateReceived(item) {
      let carrier = item.logistic?.toLowerCase()
      if (this.order?.trackings?.length) {
        if (carrier === 'car') {
          carrier = 'col_6a'
          const deliveryDate = this.order.trackings?.find(t => t.carrier?.toLowerCase() === carrier)?.deliveryDate
          return deliveryDate ? this.$moment(deliveryDate).format('DD/MM/YYYY') : null
        }
        if (carrier === 'agediss' && item.shipper === 'GLS') {
          carrier = 'glsfrance'
          const deliveryDate = this.order.trackings?.find(t => t.carrier?.toLowerCase() === carrier)?.deliveryDate
          return deliveryDate ? this.$moment.unix(deliveryDate).format('DD/MM/YYYY') : null
        }
        if (item.shipper === 'UPS') {
          carrier = 'ups'
          const deliveryDate = this.order.trackings?.find(t => t.carrier?.toLowerCase() === carrier)?.deliveryDate
          return deliveryDate ? this.$moment.unix(deliveryDate).format('DD/MM/YYYY') : null
        }
      }
      return null
    },
    async getClaims() {
      try {
        const { claims } = await this.$http.get(`/admin/order/${this.$route.params.id}/claims`)
        this.claims = claims
      } catch (err) {
        console.log(err)
      }
    },
    async getGorgiasInfo() {
      try {
        const { tickets } = await this.$http.get(`/admin/order/${this.$route.params.id}/gorgias-data`)
        this.gorgiasTicket = tickets
      } catch (err) {
        console.log(err)
      }
    },
    async getTicket(id) {
      const { ticket } = await this.$http.get(`/admin/gorgias/${id}`)
      this.gorgiasMessages = ticket
      this.showGorgiasModal = true
    },
    async upload(f) {
      if (this.file) {
        console.log(f)
        this.loading = true
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          await this.$http.post(`/admin/orders/${this.$route.params.id}/upload-document`, form)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre image a bien été sauvegardée.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.openModalJustificatif = false
          await this.getOrder()
        } catch (err) {
          console.log(err)
        } finally {
          this.loading = false
        }
      }
    },
    openDuplicate() {
      this.reset()
      if (this.isDuplicated === true) {
        this.isDuplicated = false
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of this.order.line_items) {
          this.add({
            ...item,
            price: item.price * 1.2,
            variationId: item.variation_id,
            id: item.product_id,
            images: [this.order?.products?.find(i => i._id === item?.product_id)?.src],
          })
        }
        this.isDuplicated = true
      }
    },
    getTracking(trackingNumber) {
      window.YQV5.trackSingleF1({
        YQ_ElementId: trackingNumber,
        YQ_Width: 470,
        YQ_Height: 560,
        YQ_Fc: '0',
        YQ_Lang: 'fr',
        YQ_Num: trackingNumber,
      })
    },
    async createOrder() {
      try {
        if (this.coupon && this.coupon.toLowerCase() === 'gesteco2020' && ['', null].includes(this.motif)) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez renseigner un motif pour les gestes co !',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
        } else {
          this.order.shipping.phone = this.order.shipping.phone || this.order.meta_data.find(m => m.key === '_shipping_phone')?.value
          this.order.shipping.etage = this.order.meta_data.find(m => m.key === '_shipping_etage')?.value
          this.order.shipping.ascenseur = this.order.meta_data.find(m => m.key === '_shipping_ascenseur')?.value
          this.order.shipping.email = this.order.billing.email
          const formattedOrder = products.formattedOrder({ customer_note: this.order.customer_note }, this.order.shipping, this.order.billing, this.items, null, this.discount)
          this.loading = true
          formattedOrder.status = this.status
          if (this.fee_lines?.length > 0) {
            formattedOrder.fee_lines = this.fee_lines
          }
          const { order } = await this.$http.post(`/admin/order?country=${this.order.system.shop}`, formattedOrder)
          this.order = order
          this.reset()
          this.isDuplicated = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'La commande a bien été créé.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      } finally {
        this.loading = false
        console.log('toto')
      }
    },
    async getOrder() {
      try {
        const { order } = await this.$http.get(`/admin/order/${this.$route.params.id}/dashboard`)
        this.order = order
      } catch (err) {
        console.log(err)
      }
    },
    async updateOrder(type, data) {
      try {
        let dataM = data
        if (type === 'dispatch-status' && ['Envoyé depuis boutique', 'Pris en boutique'].includes(data.dispatch)) {
          dataM.dispatch = `${data.dispatch} - ${this.specificOptionDispatch}`
        } else if (type === 'sinistre') {
          dataM = { ...dataM, sinistre: this.sinistre }
        } else if (type === 'payment-split') {
          dataM = this.splitPayments
        }
        const { order } = await this.$http.put(`/admin/order/${this.$route.params.id}`, { type, data: dataM })
        this.order = order
        await this.getOrder()
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien sauvegardé !',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    printInvoice() {
      window.print()
    },
    async checkCoupon() {
      try {
        if (this.discount?.find(d => d.name?.toLowerCase() === this.coupon?.toLowerCase())) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous ne pouvez pas utiliser deux fois le même coupon.',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        } else if (this.discount.filter(d => d.individualUse === true)?.length) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Le coupon déjà présent ne peut pas être utilisé avec un autre.',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
        } else {
          this.loading = true
          const { amount, individualUse } = await this.$http.post(`/admin/coupons/${this.coupon}?country=${this.order.system.shop}`, { items: this.items })
          if (this.discount?.length > 0 && individualUse) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Ce coupon ne peut pas être utilisé avec un autre.',
                icon: 'EditIcon',
                variant: 'error',
              },
            })
          } else {
            this.amount = amount
            await this.addDiscount({ individualUse, amount, name: this.coupon })
          }
          this.loading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async deleteComment(key) {
      try {
        const comments = this.order.sav.comments.filter((c, i) => i !== key)
        await this.$http.post(`/admin/order/${this.$route.params.id}/deleteComment`, { comments })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async addComment() {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/addComment`, { text: this.comment })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Le commentaire a bien été ajouté.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.showComment = false
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async generatePayment(count) {
      try {
        if (count === 1) {
          const { link } = await this.$http.post(`/admin/order/${this.$route.params.id}/create-payment-link`)
          this.almaLink = link
          this.showLink = true
        } else {
          const { link } = await this.$http.post(`/admin/order/${this.$route.params.id}/create-alma`, { count })
          this.almaLink = link
          this.showLink = true
        }
      } catch (err) {
        console.log(err)
      }
    },
    async sav(status) {
      try {
        await this.$http.put(`/admin/order/${this.$route.params.id}/sav`, { status })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Mise à jour réussie.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.getOrder()
      } catch (err) {
        console.log(err)
      }
    },
    async uploadFacture() {
      try {
        this.loading = true
        const { link } = await this.$http.get(`/admin/invoice/${this.$route.params.id}`)
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async uploadBC() {
      try {
        this.loading = true
        const { link } = await this.$http.get(`/admin/invoice/${this.$route.params.id}/bon-commande`)
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async uploadDevis() {
      try {
        this.loading = true
        const { link } = await this.$http.get(`/admin/invoice/${this.$route.params.id}/bon-commande?type=devis`)
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    resolveStatusVariant(status) {
      if (status === 'completed') return 'success'
      if (status === 'sended') return 'secondary'
      if (status === 'failed') return 'warning'
      if (['cancelled', 'on-hold'].includes(status)) return 'danger'
      return 'primary'
    },
    resolveStatusTranslation(status) {
      switch (status) {
        case 'processing':
          return 'En fabrication'
        case 'pending':
          return 'En attente de paiement'
        case 'on-hold':
          return 'En attente'
        case 'sended':
        case 'completed':
          return 'Expédiée'
        case 'failed':
          return 'Echouée'
        case 'cancelled':
          return 'Annulée'
        case 'refunded':
          return 'Remboursée'
        case 'rembourse-partiel':
          return 'Remboursée partiellement'
        default:
          return status
      }
    },
    changeProduct(product) {
      if (product?.deleted) {
        this.updateOrder('product', { product_id: null, id: this.selectedProduct.id })
      } else {
        this.updateOrder('product', {
          product_id: product.id, variation_id: product.variationId, total: product.price, id: this.selectedProduct.id,
        })
      }
    },
    getDays(logistic, date, sku) {
      const dates = date.split('/')
      if (['GommaGomma', 'Flexilan', 'Radi', 'Agediss'].includes(logistic) && !sku?.includes('SUR-MESURE')) {
        return this.$moment(this.$moment(`${dates[1]}/${dates[0]}/${dates[2]}`)).add(9, 'days').format('DD/MM/YYYY')
      // eslint-disable-next-line no-else-return
      } else if (sku?.includes('TURINOIS-SUR-MESURE')) {
        return this.$moment(this.$moment(`${dates[1]}/${dates[0]}/${dates[2]}`)).add(22, 'days').format('DD/MM/YYYY')
      }
      return 'Non communiqué'
    },
    showLogisticModal(sku) {
      this.showLogModal = true
      this.selectSkuLog = sku
    },
    async openSinistre(item) {
      this.productSinistre = item
      this.openModalSinistre = true
    },
    async saveSplitPayment() {
      try {
        this.loading = true
        const { link } = await this.$http.get(`/admin/invoice/${this.$route.params.id}`)
        window.open(link)
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
