<template>
  <div>
    <b-modal
      v-model="openModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Générer une étiquette"
    >
      <b-row class="mb-2">
        <b-col
          cols="6"
        >
          <b-form-group
            label="Type d'étiquette"
          >
            <v-select
              v-model="typeShip"
              :options="typeShipOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <h5>Adresse du client</h5>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Nom / Prénom"
            label-for="adresse"
          >
            <b-form-input
              v-model="shipMapped.to_address.name"
              placeholder="Nom de l'adresse"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Société"
            label-for="society"
          >
            <b-form-input
              v-model="shipMapped.to_address.company"
              placeholder="Société"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Ville"
          >
            <b-form-input
              v-model="shipMapped.to_address.city"
              placeholder="Ville"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Adresse"
            label-for="adresse"
          >
            <b-form-input
              v-model="shipMapped.to_address.street1"
              placeholder="Adresse"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Adresse 2"
          >
            <b-form-input
              v-model="shipMapped.to_address.street2"
              placeholder="Adresse 2"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Région"
          >
            <b-form-input
              v-model="shipMapped.to_address.state"
              placeholder="Région"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Code postal"
          >
            <b-form-input
              v-model="shipMapped.to_address.zip"
              placeholder="Code postal"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Pays"
          >
            <b-form-input
              v-model="shipMapped.to_address.country"
              placeholder="Pays"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Téléphone"
          >
            <b-form-input
              v-model="shipMapped.to_address.phone"
              placeholder="Téléphone"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="6"
        >
          <b-form-group
            label="Adresse du logisticien"
          >
            <v-select
              v-model="shipMapped.from_address"
              label="name"
              :options="fromAddress"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group
            label="Livreur"
          >
            <v-select
              v-model="car"
              label="nameC"
              :options="carrier"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Contenu"
          >
            <b-form-input
              v-model="shipMapped.ContentDescription"
              placeholder="Contenu"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <h5>Colis</h5>
      </b-row>
      <b-row
        v-for="(parcel, key) of shipMapped.parcels"
        :key="key"
      >
        <b-col
          cols="3"
        >
          <b-form-group
            label="Length"
          >
            <b-form-input
              v-model="parcel.length"
              type="number"
              placeholder="Length"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Width"
          >
            <b-form-input
              v-model="parcel.width"
              type="number"
              placeholder="Width"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Height"
          >
            <b-form-input
              v-model="parcel.height"
              type="number"
              placeholder="Height"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="2"
        >
          <b-form-group
            label="Weight"
          >
            <b-form-input
              v-model="parcel.weight"
              type="number"
              placeholder="Weight"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="1"
          class="primary my-4"
        >
          <b-button
            variant="primary"
            class="btn-icon"
            @click="shipMapped.parcels.splice(key, 1);"
          >
            <feather-icon icon="Trash2Icon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="4"
        >
          <b-button
            @click="shipMapped.parcels.push({
              length: 1,
              width: 1,
              height: 1,
              weight: 1
            })"
          >Add</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col
          cols="12"
        >
          <b-form-group
            label="Note"
          >
            <b-form-input
              v-model="shipMapped.note"
              placeholder="Note"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          :disabled="loading"
          @click="generateEtiquette"
        >
          <span class="align-middle">Créer l'étiquette</span>
        </b-button>
      </div>
    </b-modal>
    <b-button
      variant="primary"
      @click="openModal = !openModal"
    >
      <feather-icon
        icon="EditIcon"
        class="mr-50"
      />
      <span class="align-middle">Etiquette</span>
    </b-button>
    <b-button
      v-if="order.system && order.system.ship && order.system.ship.url"
      variant="primary"
      class="ml-2"
    >
      <feather-icon
        icon="DownloadIcon"
        class="mr-50"
      />
      <span
        class="align-middle"
        @click="openShippingTicket(order.system.ship.url)"
      >Ouvrir étiquette</span>
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, VBToggle, BModal, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BModal,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openModal: false,
      loading: false,
      typeShip: 'retour',
      typeShipOptions: ['retour', 'envoie'],
      fromAddress: [
        {
          name: 'Kipli c/o Flexilan 2000 Kipli SRL',
          company: 'kipli c/o Flexilan 2000',
          street1: 'Via Cristoforo Colombo 1',
          street2: '',
          city: 'Robassomero',
          state: 'TO',
          zip: '10070',
          country: 'IT',
          phone: '+39 011 924 1110',
          email: 'operations@kipli.com',
        },
        {
          name: 'Kipli c/o Gomma Gomma',
          company: 'Kipli c/o Gomma Gomma',
          street1: 'Via Asiago 500',
          street2: '',
          city: 'Caronno Pertusella',
          state: 'VA',
          zip: '21042',
          country: 'IT',
          phone: '+39 02 965 1001',
          email: 'operations@kipli.com',
        },
        {
          name: 'Kipli c/o Luca Radi',
          company: 'Kipli c/o Luca Radi',
          street1: 'Via Kennedy 8',
          street2: '',
          city: 'ROBASSOMERO',
          state: 'TO',
          zip: '10070',
          country: 'IT',
          phone: '0119241044',
          email: 'operations@kipli.com',
        },
        {
          name: 'Agediss',
          company: 'Agediss',
          street1: 'Av Robert Bauer, Bat D',
          street2: '',
          city: 'MER',
          state: 'FR',
          zip: '41500',
          country: 'FR',
          phone: '0614822853',
          email: 'rendezvouslog@agediss.com',
        },
        {
          name: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          company: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          street1: 'ZI 1 ERE AVENUE',
          street2: '',
          city: 'CARROS CEDEX',
          state: 'FR',
          zip: '06517',
          country: 'FR',
          phone: '0493081050',
          email: 'operations@kipli.com',
        },
        {
          name: 'KIPLI',
          company: 'KIPLI',
          street1: '15 rue Beautreillis',
          street2: '',
          city: 'PARIS',
          state: 'FR',
          zip: '75004',
          country: 'FR',
          phone: '0761220602',
          email: 'operations@kipli.com',
        },
        {
          name: 'LUNA',
          company: 'ANA RO',
          street1: '15 rue Beautreillis',
          street2: '',
          city: 'PARIS',
          state: 'FR',
          zip: '75004',
          country: 'FR',
          phone: '0761220602',
          email: 'operations@kipli.com',
        },
      ],
      car: {},
      carrier: [
      //   {
      //     nameC: 'UPS Italie',
      //     CarrierName: 'UPS',
      //     CarrierID: 2129,
      //     CarrierService: 'UPSStandard',
      //   },
      //   {
      //     nameC: 'UPS France',
      //     CarrierName: 'UPS',
      //     CarrierID: 7318,
      //     CarrierService: 'UPSStandard',
      //   },
        {
          nameC: 'UPS Italie V2',
          CarrierName: 'UPSv2',
          CarrierID: 4178,
          CarrierService: 'UPS Standard',
        },
        {
          nameC: 'UPS France V2',
          CarrierName: 'UPSv2',
          CarrierID: 4177,
          CarrierService: 'UPS Standard',
        },
        // {
        //   nameC: 'UPS Italie - Retour',
        //   CarrierName: 'UPS',
        //   CarrierID: 8018,
        //   CarrierService: 'UPSStandard',
        // },
        // {
        //   nameC: 'UPS France - Retour',
        //   CarrierName: 'UPS',
        //   CarrierID: 8017,
        //   CarrierService: 'UPSStandard',
        // },
        {
          nameC: 'BRT Flexilan - Radi',
          CarrierName: 'BRT',
          CarrierID: 4316,
          CarrierService: 'Standard',
          CarrierOptions: [
            {
              name: 'brtappointment_delivery',
              value: '1',
            },
            {
              name: 'brtfloor_delivery',
              value: '1',
            },
          ],
        },
        {
          nameC: 'BRT GG',
          CarrierName: 'BRT',
          CarrierID: 4315,
          CarrierService: 'Distribuzione',
          CarrierOptions: [
            {
              name: 'brtappointment_delivery',
              value: '1',
            },
            {
              name: 'brtfloor_delivery',
              value: '1',
            },
          ],
        },
        // {
        //   nameC: 'GLS France',
        //   CarrierName: 'GLSFrance',
        //   CarrierID: 211,
        //   CarrierService: 'Business Parcel',
        // },
        // {
        //   nameC: 'GLS',
        //   CarrierName: 'GLS',
        //   CarrierID: 624,
        //   CarrierService: 'Standard',
        // },
        {
          nameC: 'GLS V2',
          CarrierName: 'GLS',
          CarrierID: 844,
          CarrierService: 'Standard',
        },
        {
          nameC: 'Colissimo (Compte Car)',
          CarrierName: 'Colissimo',
          CarrierID: 11520,
          CarrierService: 'Standard',
        },
        {
          nameC: 'Colissimo (Compte Kipli)',
          CarrierName: 'Colissimo',
          CarrierID: 11521,
          CarrierService: 'Standard',
        },
        {
          nameC: 'Generic',
          CarrierName: 'Generic',
          CarrierID: 3066,
          CarrierService: 'Standard',
        },
        {
          nameC: 'Fedex',
          CarrierName: 'FEDEX',
          CarrierID: 5611,
          CarrierService: 'International Connect Plus',
        }],
      shipMapped: {
        note: '',
        to_address: {
          name: `${this.order?.shipping?.first_name} ${this.order?.shipping?.last_name}`,
          company: this.order?.shipping?.company,
          street1: this.order?.shipping?.address_1,
          street2: this.order?.shipping?.address_2,
          city: this.order?.shipping?.city,
          state: this.order?.shipping?.state,
          zip: this.order?.shipping?.postcode,
          country: this.order?.shipping?.country,
          phone: this?.order?.meta_data.find(m => m.key === '_shipping_phone') ? this?.order?.meta_data.find(m => m.key === '_shipping_phone').value : this?.order?.shipping?.phone,
          email: this.order?.billing?.email,
        },
        from_address: null,
        ContentDescription: this.order.itemsLogistics?.filter(item => item.dimension)?.map(item => item?.sku)?.join('/'),
        parcels: this.order.itemsLogistics?.filter(item => item.dimension)?.map(item => ({ ...item?.dimension })),
      },
    }
  },
  methods: {
    openShippingTicket(link) {
      if (Array.isArray(link)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const l of link) {
          window.open(l)
        }
      } else {
        window.open(link)
      }
    },
    async generateEtiquette() {
      try {
        this.loading = true
        this.shipMapped = {
          ...this.shipMapped,
          parcels: this.shipMapped?.parcels?.map(p => ({
            height: Number(p?.height),
            length: Number(p?.length),
            weight: Number(p?.weight),
            width: Number(p?.width),
          })),
        }
        if (['', null, undefined].includes(this.order?.shipping?.company)) {
          this.shipMapped.to_address.company = this.shipMapped.to_address.name
        }
        const { link } = await this.$http.post(
          `/admin/shippypro/${this.order.number}`, { ...this.shipMapped, ...this.car, type: this.typeShip },
        )
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        if (Array.isArray(link)) {
          // eslint-disable-next-line no-restricted-syntax
          for (const l of link) {
            window.open(l)
          }
        } else {
          window.open(link)
        }
        this.openModal = false
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Il y a eu une erreur',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
